export const SAMPLE_COURSE = 'sampleCourse';
export const SAMPLE_MESSAGES = 'sampleMessages';
export const COURSE_TITLE = 'courseTitle';
export const COURSE_IMAGE = 'courseImage';
export const COURSE_ICON = 'courseIcon';
export const PUBLISH_STATUS = 'publishStatus';
export const PUBLISH_STATUS_SAMPLE = 'publishStatusSample';
export const COURSE_LESSONS = 'courseLessons';
export const COURSE_LESSONS_SAMPLE = 'courseLessonsSample';
export const LESSON_TEMPLATES = 'lessonTemplates';
export const READY_STATUS = 'readyStatus';
export const LESSON_BUBBLES = 'lessonBubbles';
export const INTRO_READY = 'introReady';
export const INTRO_READY_SAMPLE = 'introReadySample';
export const INTRO_NOT_READY = 'introNotReady';
export const LONG_TEXT = 'longText';
export const LONG_TITLE_TEXT = 'longTitleText';
export const LESSON_READY = 'lessonReady';
export const COURSE_READY = 'courseReady';
export const LESSON_ASSIGN = 'lessonAssign';
export const LESSON_ASSIGN_WEB_ONLY = 'lessonAssignWebOnly';
export const ASSIGN_TEAMS = 'assignTeams';
export const ASSIGN_TEAMS_SAMPLE = 'assignTeamsSample';
export const ADD_PEOPLE = 'addPeople';
export const SAMPLE_TEAM = 'sampleTeam';
export const NO_LEARNERS = 'noLearners';
export const EMPTY_REPORTS = 'emptyReports';
export const AUTHORING_INTRO = 'authoringIntro';
export const AUTHORING_OUTCOMES = 'authoringOutcomes';
export const AUTHORING_IMAGE = 'authoringImage';
export const AUTHORING_VIDEO = 'authoringVideo';
export const AUTHORING_TEXT = 'authoringText';
export const AUTHORING_QUIZ_INTRO = 'authoringQuizIntro';
export const AUTHORING_QUIZ_QUESTIONS = 'authoringQuizQuestions';
export const LEARNING_CARD_HELP = 'learningCardHelp';
