import amplitude from 'amplitude-js';

export * as ampProperties from './amplitudeProps';

type UserProps = {
  [key: string]: string | number;
};

export const amplitudeFunctions = (sdk: typeof amplitude) => {
  const initialiseAmplitude = async (apiKey: string) => {
    await sdk.getInstance().init(apiKey, '', {disableCookies: true});
  };

  const setAmplitudeUserId = async (userId: number | null) => {
    const stringifiedUserId = userId === null ? null : String(userId);
    await sdk.getInstance().setUserId(stringifiedUserId);
  };

  const setAmplitudeUserProps = async (userProps: UserProps) => {
    await sdk.getInstance().setUserProperties(userProps);
  };

  const setAmplitudeGroup = async (
    value: number | string,
    groupType = 'customer'
  ) => {
    await sdk.getInstance().setGroup(groupType, String(value)); // Amplitude expects a string
  };

  const logAmplitudeEvent = async (eventName: string, eventProps = {}) => {
    await sdk.getInstance().logEvent(eventName, eventProps);
  };

  const regenerateAmplitudeDeviceId = async () => {
    await sdk.getInstance().regenerateDeviceId();
  };

  return {
    initialiseAmplitude,
    setAmplitudeUserId,
    setAmplitudeUserProps,
    setAmplitudeGroup,
    logAmplitudeEvent,
    regenerateAmplitudeDeviceId,
  };
};
