import {
  SurveyQuestion,
  EnpsSurveyQuestion,
  FreeTextSurveyQuestion,
  MultipleChoiceSurveyQuestion,
} from '../types/survey';

export const isLengthInRange = (
  val: string | unknown[] | null,
  min: number,
  max: number,
  shouldAcceptEmptyString = false
) =>
  (val || (shouldAcceptEmptyString && val === '')) &&
  val.length >= min &&
  val.length <= max;

const validateMultipleChoice = (question: MultipleChoiceSurveyQuestion) => {
  const {question: questionText, options = []} = question.params;
  if (!questionText || !isLengthInRange(questionText, 1, 80)) {
    return false;
  }
  if (!isLengthInRange(options, 2, 5)) {
    return false;
  }
  for (const option of options) {
    if (!isLengthInRange(option, 1, 60)) {
      return false;
    }
  }
  return true;
};

const validateEnpsScore = (question: EnpsSurveyQuestion) => {
  const {
    question: questionText,
    lowerBoundMessage,
    upperBoundMessage,
  } = question.params;
  if (!questionText || !isLengthInRange(questionText, 1, 82)) {
    return false;
  }
  if (!lowerBoundMessage || !isLengthInRange(lowerBoundMessage, 1, 30)) {
    return false;
  }
  if (!upperBoundMessage || !isLengthInRange(upperBoundMessage, 1, 30)) {
    return false;
  }

  return true;
};

const validateFreeText = (question: FreeTextSurveyQuestion) =>
  question.params.question && isLengthInRange(question.params.question, 1, 80);

// eslint-disable-next-line complexity
export const validateSurveyQuestions = (questions?: SurveyQuestion[]) => {
  if (!questions || !Array.isArray(questions) || questions.length === 0) {
    return false;
  }

  for (const question of questions) {
    switch (question.type) {
      case 'multipleChoice':
        if (!validateMultipleChoice(question)) {
          return false;
        }
        break;
      case 'enpsScore':
        if (!validateEnpsScore(question)) {
          return false;
        }
        break;
      case 'freeText':
        if (!validateFreeText(question)) {
          return false;
        }
        break;
      default:
        return false;
    }
  }

  return true;
};
