import {isLengthInRange} from './surveyValidation';
import {isValidCustomisedButtonText} from './lessonValidation';
import {CompletionScreenParams} from '../types/course';

// eslint-disable-next-line complexity
export const validateCompletionScreen = (params: CompletionScreenParams) => {
  const {title, subtitle, message, buttonText, buttonUrl, showDashboardLink} =
    params;

  if (!isLengthInRange(title, 1, 35)) {
    return false;
  }
  if (subtitle && !isLengthInRange(subtitle, 0, 30, true)) {
    return false;
  }
  if (message && !isLengthInRange(message, 0, 200, true)) {
    return false;
  }
  if (!isValidCustomisedButtonText(buttonText)) {
    return false;
  }
  if (!buttonUrl && showDashboardLink) {
    return false;
  }
  return true;
};
