import {z} from 'zod';

interface Rule {
  op: 'and' | 'or';
  criteria: (Rule | Criteria)[];
}

interface Criteria {
  table: 'users_tags' | 'stats_users_courses' | 'users_groups' | 'users';
  where: Where[];
  user_id_column: string;
}

interface Where {
  column: string;
  op: 'equals' | 'contains' | 'greater than' | 'less than' | 'before' | 'after';
  value: string | Date | number;
}

const RuleParser: z.ZodType<Rule> = z.late
  .object(() => ({
    op: z.enum(['and', 'or']),
    criteria: z.array(z.union([RuleParser, CriteriaParser])),
  }))
  .strict();

const CriteriaParser: z.ZodType<Criteria> = z.late
  .object(() => ({
    table: z.enum([
      'users_tags',
      'stats_users_courses',
      'users_groups',
      'users',
    ]),
    where: z.array(WhereParser),
    user_id_column: z.string(),
  }))
  .strict();

const WhereParser: z.ZodType<Where> = z
  .object({
    column: z.string(),
    op: z.enum([
      'equals',
      'contains',
      'greater than',
      'less than',
      'before',
      'after',
    ]),
    value: z.union([z.string(), z.number(), z.date()]),
  })
  .strict();

export const validateSegment = (rules: any, debug = false) => {
  const result = RuleParser.safeParse(rules);
  if (debug) {
    console.log(result);
  }
  return result.success;
};
