export const ACTIVATION_DATE = 'activated at';
export const ACTIVATION_METHOD = 'activation method';
export const ADMIN_LEVEL = 'admin level';
export const EMAIL = 'email';
export const EMPLOYEE_ID = 'employee ID';
export const GROUP_ID = 'group ID';
export const GROUP_NAME = 'group name';
export const INVITATION_DATE = 'invited at';
export const LOGIN_TYPE = 'login type';
export const SEAMLESS_LINK_CONTENT_TYPE = 'seamless link content type';
export const PERMISSIONS = 'permissions';
export const PHONE = 'phone';
export const USER_ID = 'user ID';
export const USER_TYPE = 'user type';
export const USER_IDENTIFIER = 'user identifier';
export const TENANT_ID = 'tenant ID';
export const MS_TEAMS_COMPANY_NAME = 'ms teams company name';

// Content
export const CONTENT_ID = 'content id';
export const CONTENT_TYPE = 'content type';

// Courses
export const COURSE_ID = 'course id';
export const MODULE_ID = 'module id';
export const COURSE_TITLE = 'course name';
export const COURSE_IMAGE_ADDED = 'course image added';
export const NUMBER_OF_SURVEYS = 'number of surveys';
export const NUMBER_OF_LESSONS = 'number of lessons';
export const NUMBER_OF_ASSESSMENTS = 'number of assessments';
export const NUMBER_OF_MODULES = 'number of modules';
export const NUMBER_OF_ACTIVITIES = 'number of activities';
export const COURSE_LESSON_FORMAT = 'course lesson format';
export const COURSE_DURATION = 'estimated course duration';
export const DUPLICATED_COURSE = 'duplicate of course';
export const SELECTED_LANGUAGE = 'selected language';
export const EDITED_TEXT = 'edited text';
export const LINK_ADDED = 'link added';
export const CERTIFICATE = 'certificate';
export const HIDE_TITLE_SLIDE = 'hide title slide';
export const COURSE_LANGUAGES = 'course languages';
export const COURSE_REPORT_LINK_TYPE = 'course report link type';
export const DUPLICATED_FROM = 'duplicated from';
export const PREVIEW_ID = 'preview ID';
export const LINK_TYPE = 'link type';
export const PRIORITY_STATUS = 'priority status';
export const ORIGINAL_COURSE_ID = 'original course id';

// Lessons & assessments
export const ACTIVITY_ID = 'activity id';
export const LESSON_ID = 'lesson id';
export const COMPONENT_ID = 'component id';
export const ASSESSMENT_ID = 'assessment id';
export const ACTIVITY_FORMAT = 'activity format';
export const ACTIVITY_LIST_FORMAT = 'activity list format';
export const ACTIVITY_LIST_ORDER = 'activity list order';
export const ACTIVITY_TYPE = 'activity type';
export const LESSON_TYPE = 'lesson type';
export const COMPONENT_TYPE = 'component type';
export const TEMPLATE_TYPE = 'template type';
export const CUSTOMISED = 'customised';
export const IMAGE_UPLOADED = 'image uploaded';
export const VIDEO_UPLOADED = 'video uploaded';
export const IMAGE_REPLACED = 'image replaced';
export const VIDEO_REPLACED = 'video replaced';
export const IMAGE_ADDED = 'image added';
export const SELECTED_COLOUR = 'selected colour';
export const SELECTED_DEFAULT_COLOUR = 'selected default colour';
export const SELECTED_ICON = 'selected icon';
export const SELECTED_DEFAULT_ICON = 'selected default icon';
export const COLOUR_TYPE = 'colour type';
export const ASSESSMENT_MINIMUM_SCORE = 'assessment minimum score';
export const ASSESSMENT_NUMBER_OF_ATTEMPTS = 'assessment number of attempts';
export const THIRD_PARTY_LINK_TYPE = 'third-party link type';

// Surveys
export const SURVEY_ID = 'survey id';
export const SURVEY_TYPE = 'survey type';
export const SURVEY_NAME = 'survey name';
export const ADDED_SURVEY_FOLLOW_UP = 'added survey follow-up';
export const HAS_SURVEY_FOLLOW_UP = 'has survey follow-up';
export const ANSWERED_SURVEY_FOLLOW_UP = 'answered survey follow-up';
export const NUMBER_OF_SURVEY_OPTIONS = 'number of survey options';
export const VIEW_TYPE = 'view type';

// Course list
export const HOME_TYPE = 'home type';
export const COURSES_ASSIGNED = 'number of courses assigned';
export const COURSES_NOT_STARTED = 'number of courses not started';
export const COURSES_IN_PROGRESS = 'number of courses in progress';
export const COURSES_COMPLETED = 'number of courses completed';
export const COURSES_REASSIGNED = 'number of retakes assigned';
export const RETAKE_TYPE = 'retake type';

// Messages
export const MESSAGE_ID = 'message id';
export const EXPIRATION = 'expiration';
export const SCHEDULED = 'scheduled';
export const PINNED = 'pinned';
export const MEDIA_TYPE = 'media type';
export const TITLE = 'title';

// Reports
export const REPORT_LEVEL = 'report level';
export const REPORT_TYPE = 'report type';
export const FILTER_TYPE = 'filter type';
export const USER_STATUS = 'user status';
export const REPORT_USER_SEGMENT = 'report user segment';
export const DAY_RANGE = 'day range';

// Groups
export const TEAM_ID = 'team id';
export const TEAM_NAME = 'team name';
export const NUMBER_OF_TEAMS_ASSIGNED = 'number of teams assigned';
export const NUMBER_OF_TEAMS_UNASSIGNED = 'number of teams unassigned';
export const NUMBER_OF_USERS_ASSIGNED = 'number of users';
export const NUMBER_OF_ACTIVATED_USERS_ASSIGNED = 'number of activated users';
export const NUMBER_OF_NON_ACTIVATED_USERS_ASSIGNED =
  'number of non-activated users';
export const NET_CHANGE_TEAMS_ASSIGNED = 'net change in teams assigned';

// Users
export const TOTAL_TEAMS_ASSIGNED = 'total teams assigned';
export const TOTAL_TEAMS_UNASSIGNED = 'total teams unassigned';
export const LOGIN_TYPE_CHANGED = 'login type changed';
export const OTHER_INFO_UPDATED = 'other info updated';
export const LOGIN_TYPE_VALUE_UPDATED = 'login type value updated';
export const LAST_NAME_UPDATED = 'last name updated';
export const FIRST_NAME_UPDATED = 'first name updated';
export const ADD_USER_METHOD = 'add user method';
export const NUMBER_OF_USERS_DOWNLOADED = 'number of users downloaded';
export const NUMBER_OF_USERS_ADDED = 'number of users added';
export const DEFAULT_VIEW_UPDATED = 'default view updated';
export const SEARCH_TERM = 'search term';
export const SORT_TYPE = 'sort type';
export const GROUP_TYPE = 'group type';

// Knowledge hubs
export const KNOWLEDGE_HUB_NAME = 'knowledge hub name';
export const KNOWLEDGE_HUB_CONFIGURATION = 'knowledge hub configuration';

// Settings
export const PERMISSION_LEVEL = 'permission level';
export const KEY_ID = 'key ID';
export const SECRET_ID = 'secret ID';
export const WEBHOOK_ID = 'webhook ID';

// Tests
export const MULTIVARIANT_TESTS = 'multivariant tests';
export const MULTIVARIANT_TEST_VARIANTS = 'multivariant test variants';

// Course settings
export const CUSTOMER = 'customer';
export const INTEGRATION_NAME = 'integration name';
export const STATUS = 'status';
export const CUSTOM_NAME = 'custom name';
export const WEBHOOK_NAME = 'webhook name';

// Other
export const SOURCE = 'source';
export const PAGE_TITLE = 'page title';
export const VIEWPORT_HEIGHT = 'viewport height';
export const VIEWPORT_WIDTH = 'viewport width';
export const SEARCH_TYPE = 'search type';
export const NUMBER_OF_RESULTS_RETURNED = 'number of results returned';
export const OPTION_SELECTED = 'option selected';
export const TYPE_OF_ID = 'type of id';
export const ENABLED = 'enabled';
export const QR_CODE = 'qrCode';

// Branding
export const HAS_TABS = 'show tabs';
export const LAYOUT_VERSION = 'layout version';
export const HAS_SEARCH_BAR = 'show search bar';
export const HAS_COURSE_TIME_ESTIMATE = 'show course time estimate';
export const HAS_COURSE_ACTIVITIES = 'show course activities';
export const BORDER_RADIUS = 'border radius';
export const PROGRESS_BAR_COLOR = 'progress bar colour';
export const DASHBOARD_HEADER_COLOR = 'dashboard header colour';
export const DASHBOARD_NAME = 'dashboard name';
export const LOGO_URL = 'logo URL';
export const DARK_LOGO_URL = 'dark logo URL';
export const COLOUR_PRIMARY = 'colour primary';
export const COLOUR_PRIMARY_DARK = 'colour primary dark';
export const COLOUR_SECONDARY = 'colour secondary';
export const COLOUR_TERTIARY = 'colour tertiary';
