const encode = (data: string) => encodeURIComponent(data);

type ZLib = {
  deflateRawSync: (input: string | ArrayBuffer) => Buffer;
};

export const generateAuthRequest = (zlib: ZLib, acsUrl: string, id: string) => {
  // the id provided is a js object containing groupId, moduleId, lessonId, surveyId encoded with btoa
  // this could include padding (the = sign) to make the last encoded block contain four Base64 characters
  // see https://en.wikipedia.org/wiki/Base64#Examples
  // we later use atob to decode this string to redirect the user to the correct content
  // the padding is stripped to conform with the expected Azure's id format
  // in js atob is able to correctly decode the string without the padding
  // see https://en.wikipedia.org/wiki/Base64#HTML
  const sanitisedId = id.replaceAll('=', '');
  const requestXml = `<samlp:AuthnRequest
      xmlns:samlp="urn:oasis:names:tc:SAML:2.0:protocol"
      xmlns:saml="urn:oasis:names:tc:SAML:2.0:assertion"
      xmlns="urn:oasis:names:tc:SAML:2.0:metadata"
      Version="2.0"
      IssueInstant="${new Date().toISOString()}"
      ID="id_${sanitisedId}"
    >
      <saml:Issuer>${acsUrl}</saml:Issuer>
      <samlp:NameIDPolicy Format="urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress"/>
    </samlp:AuthnRequest>`;

  const deflated = zlib.deflateRawSync(requestXml).toString('base64');

  return encode(deflated);
};
